import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useToast } from './ToastProvider';
import axios from 'axios';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { Channel, LocalNotifications } from '@capacitor/local-notifications'
import { Device } from '@capacitor/device';
import { useAuth } from './AuthProvider';
import { useHistory, matchPath } from 'react-router';
import useLocalStorageState from 'use-local-storage-state';
import { Communication } from '../pages/CommunicationDetails';
import { v4 as uuidv4 } from 'uuid';

type FirebaseMessagingProviderOptions = {
  fcmToken: String | null
  setFcmToken: Dispatch<SetStateAction<String | null>>
  messagesCount: number
  setMessagesCount: Dispatch<SetStateAction<number>>
  refresh: Boolean
  setRefresh: Dispatch<SetStateAction<Boolean>>
  refreshChatList: Boolean
  setRefreshChatList: Dispatch<SetStateAction<Boolean>>
  communicationsArchive: Array<Communication>
  setCommunications: Dispatch<SetStateAction<Communication[]>>
  loadMessagesStatus: Function
  resetFCMToken: Function
  clearCommunicationArchive: Function
};

type Params = {
  id: string;
};

const FirebaseMessagingContext = createContext<FirebaseMessagingProviderOptions | null>(null);

export const useMessaging = () => useContext(FirebaseMessagingContext) as FirebaseMessagingProviderOptions;

export const FirebaseMessagingProvider: React.FC<PropsWithChildren> = ({ children }) => {
  
  const [communicationsArchive, setCommunications] = useLocalStorageState<Array<Communication>>('communicationsArchive', {
    defaultValue: []
  })

  const [fcmToken, setFcmToken] = useState<String | null>(null)
  const [messagesCount, setMessagesCount] = useState<number>(-1)
  const [refresh, setRefresh] = useState<Boolean>(false)
  const [refreshChatList, setRefreshChatList] = useState<Boolean>(false)
  const toast = useToast();

  const { user, b2bUser } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (fcmToken && b2bUser && b2bUser.fcm_registration_token !== fcmToken) {
      axios.put("user/fcm_registration_token", { fcm_registration_token: fcmToken }).catch((error) => {
        console.error(error)
        toast.error("Errore nell'aggiornamento del token FCM")
      });
    }
  }, [b2bUser, fcmToken])

  useEffect(() => {
    if (user)
      setupPushNotification()
  }, [user])

  const clearCommunicationArchive = () => {
    setCommunications([])
  }

  const handleForegroundNotification = (data: any) => {
    const match = matchPath<Params | null>(history.location.pathname, {
      path: "/chats/:id",
      exact: true,
      strict: false
    });

    if (history.location.pathname === '/chats' ||
      (match && history.location.pathname.indexOf('/chats/') >= 0 && data.borrow_id === match?.params?.id)) {
      setRefresh(true)
      return true
    }
    return false
  }

  const handleMessage = (data: any) => {
    loadMessagesStatus()
    const { borrow_id, type, ...extra } = data

    switch(type) {
      case 'message': 
        history.replace(`/chats/${borrow_id}`)
        break
      case 'new-communication':
        const id = uuidv4()
        const list = communicationsArchive
        list.unshift({
          id, 
          title: extra.title, 
          short_message: extra.body,
          body: extra.message,
        })
        setCommunications(list)

        history.replace(`/profile/communications`, {id})
        break
    }
  }

  const setupPushNotification = async () => {
    const deviceInfo = await Device.getInfo()

    const isMobile = deviceInfo.platform && (['ios', 'android'].includes(deviceInfo.platform))

    if (!isMobile) {
      return
    }

    PushNotifications.checkPermissions().then(async (res) => {
      if (['prompt', 'prompt-with-rationale'].includes(res.receive)) {
        PushNotifications.requestPermissions().then(async (res) => {
          if (res.receive === 'denied') {
            toast.warning("Permessi notifiche non accettati")
          }
          else {
            await PushNotifications.register()
          }
        });
      }
      else if(res.receive === 'granted') {
        await PushNotifications.register()
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      async (token: Token) => {
        console.log('registrationCapacitor', token.value)
        if (token) {
          try {
            setFcmToken(token.value)
          } catch (error) {
            console.error(error)
            toast.error("Errore nell'aggiornamento del token FCM")
          }
        }
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        toast.error('Error on registration: ' + JSON.stringify(error));
      }
    );

    const isAndroid = deviceInfo.platform === 'android'

    if (isAndroid) {
      const notificationChannel: Channel = {
        // id must match android/app/src/main/res/values/strings.xml's default_notification_channel_id
        id: 'default-channel',
        name: 'Default',
        description: 'Tutte le Notifiche',
        importance: 5,
        visibility: 1,
        vibration: true
      }

      LocalNotifications.createChannel(notificationChannel)
    }

    // solo su android mostrare un notifica in foreground su ios lo fa gia il sistema
    PushNotifications.addListener(
      'pushNotificationReceived',
      async (payload) => {
        // foreground
        console.info('pushNotificationReceived', payload)

        const data = payload?.data ?? {}

        const ok = handleForegroundNotification(data)

        if (!ok) {
          const title = data.title || payload.title
          const body = data.body || payload.body

          await LocalNotifications.schedule({
            notifications: [
              {
                title,
                body,
                id: Math.floor(Math.random() * 10000) + 1,
                schedule: { at: new Date(Date.now() + 1000) },
                channelId: 'default-channel',
                sound: 'default',
                actionTypeId: '',
                extra: payload
              }
            ]
          })
        }
      }
    )

    PushNotifications.addListener('pushNotificationActionPerformed',
      (payload) => {
        console.log("pushNotificationActionPerformed", payload)
        const data = payload.notification?.data ?? {}
        data.title = payload.notification?.data?.title
        data.body = payload.notification?.data?.body
        handleMessage(data)
      }
    );

    LocalNotifications.addListener('localNotificationActionPerformed', (payload) => {
      console.info('localNotificationActionPerformed', payload)
      const data = payload.notification.extra.data
      data.title = payload.notification?.title
      data.body = payload.notification?.body
      handleMessage(data)
    })
  }

  const loadMessagesStatus = async () => {
    try {
      const { data } = await axios.get('/user/messages_status')
      setMessagesCount(data.count)
      setRefreshChatList(data.count > 0)
    } catch (error: any) {
      console.error(error)
      toast.error(`Errore nel recupero del numero delle chat non lette: ${error.message ?? error.response.message}`)
      // clearInterval(polling)
    }
  }

  const resetFCMToken = async () => {
    try {
      await axios.put("user/fcm_registration_token", { fcm_registration_token: null })
    } catch (error) {
      console.error(error)
      toast.error("Errore nell'aggiornamento del token FCM")
    }
  }

  return (
    <FirebaseMessagingContext.Provider
      value={{
        fcmToken,
        setFcmToken,
        messagesCount,
        setMessagesCount,
        refresh,
        setRefresh,
        loadMessagesStatus,
        resetFCMToken,
        refreshChatList,
        setRefreshChatList,
        communicationsArchive,
        setCommunications,
        clearCommunicationArchive
      }}
    >
      {children}
    </FirebaseMessagingContext.Provider>
  );
};